import React from 'react';
import './Events.css';


export default function Events() {
return (
<div id="Music" className="tabcontent">

</div>
);
}
